import React, { FC, useCallback } from 'react';
import { gtmService } from 'gatsby-theme-husky/src/utils/gtmService';

import Image from '../../../../../../gatsby-theme-husky/src/common/Image';
import { ShoppingOptionsCardProps } from '../../../../../../gatsby-theme-husky/src/components/ShoppingOptions/ShoppingOptionsCard/models';
import { DangerouslySetInnerHtml } from '../../../../../../gatsby-theme-husky/src/layout';

import '../../../../../../gatsby-theme-husky/src/components/ShoppingOptions/ShoppingOptionsCard/ShoppingOptionsCard.scss';

const ShoppingOptionsCard: FC<ShoppingOptionsCardProps> = ({
  image,
  variants,
  description,
  variantsDescription,
  buyNowTitle,
  buyNowOption,
  onFocus,
  sku,
  productName,
  productCategory,
  emitGtmEvent,
}) => {
  const retailerClickHandler = useCallback(
    (retailerName) => () => {
      gtmService.emitRetailerClick(
        {
          productName,
          productCategory,
          sku,
          variants,
        },
        retailerName
      );
    },
    []
  );

  return (
    <div className="shopping-options-card" data-testid="shopping-options-card">
      <Image imageData={image} alt="" objectFit="contain" />
      <h3 className="shopping-options-card__variant">{variants}</h3>
      {variantsDescription ? (
        <p className="shopping-options-card__variant-description">{variantsDescription}</p>
      ) : null}
      <DangerouslySetInnerHtml
        className="shopping-options-card__description"
        element="p"
        html={description}
      />
      <h4 className="shopping-options-card__buy-now-title">{buyNowTitle}</h4>
      <ul className="shopping-options-card__buy-now-options">
        {buyNowOption.map(({ image: buyNowImage, link, alt: buyNowAlt }) => (
          <li key={link} className="shopping-options-card__buy-now-option">
            <a
              className="shopping-options-card__buy-now-link"
              href={link}
              onFocus={onFocus}
              target="_blank"
              rel="noreferrer noopener"
              {...(emitGtmEvent && { onClick: retailerClickHandler(buyNowAlt) })}
            >
              <Image imageData={buyNowImage} alt={buyNowAlt} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShoppingOptionsCard;
